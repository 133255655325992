export default [
  {
    title: 'Dashboard',
    route: '/',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Manage Users',
        route: 'users',
        icon: 'UserIcon',
      },
      {
        title: 'Manage Artworks',
        route: 'artworks',
        icon: 'ApertureIcon',
      },
    ],
  },
  {
    title: 'Others',
    route: '/',
    icon: 'FileTextIcon',
  },
]
