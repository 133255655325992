export const initialAbility = [
  // {
  //   action: 'read',
  //   subject: 'Auth',
  // },
  {
    role: 'ROLE_USER',
  },
]

export const _ = undefined
